import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { theme } from './themes/theme';
import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import TeacherDashboard from './pages/teacher/dashboard';
import StudentDashboard from './pages/teacher/student';
import AlertMessage from './components/AlertMessage';
import TeacherLoginRequired from './components/TeacherLoginRequired';
import React from 'react';
import Background from './components/Background';
import ParentLoginRequired from './components/ParentLoginRequired';
import ParentDashboard from './pages/parent/dashboard';

function App() {

	const ybugSetting = () => {
		window.ybug_settings = { "id": "028kq0gvvmas8d8x9wz8" };
		var ybug = document.createElement('script'); ybug.type = 'text/javascript'; ybug.async = true;
		ybug.src = 'https://widget.ybug.io/button/' + window.ybug_settings.id + '.js';
		var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ybug, s);
	}

	const ga4Setting = () => {
		window.dataLayer = window.dataLayer || [];
		function gtag() { window.dataLayer.push(arguments); }
		gtag('js', new Date());

		gtag('config', 'G-X8QBJDXKTF');
	}

	React.useEffect(() => {
		ybugSetting();
		ga4Setting();
		if (window.Capacitor)
			window.Capacitor.Plugins.SplashScreen.hide();
	}, []);

	return (
		<>
			<header>
				<link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet' />
			</header>

			<script async src="https://www.googletagmanager.com/gtag/js?id=G-X8QBJDXKTF"></script>

			<ThemeProvider theme={responsiveFontSizes(theme, { breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'], factor: 5 })}>
				<AlertMessage />
				<Background />
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Navigate to="/teacher" />} />
						<Route path="/teacher" element={<TeacherLoginRequired><TeacherDashboard /></TeacherLoginRequired>} />
						<Route path="/student/:studentID" element={<TeacherLoginRequired><StudentDashboard /></TeacherLoginRequired>} />
					</Routes>
					<Routes>
						<Route path="/parent" element={<ParentLoginRequired><ParentDashboard /></ParentLoginRequired>} />

					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</>
	);
}

export default App;
