import React from 'react';
import { logout, useTeacherStudents, useUser } from '../../../utils/firebase';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './useStyles';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import StudentSettings from './StudentSettings';
import TeacherSettings from './TeacherSettings';
import StudentDisplay from './StudentDisplay';
import { useLocation } from 'react-router-dom';
import EmailVerificationMessage from '../../../components/EmailVerificationMessage';

const TeacherDashboard = () => {
    const logo = "/img/logo.svg";
    const classes = useStyles();
    const [openStudentSettings, setOpenStudentSettings] = React.useState(false);
    const [openTeacherSettings, setOpenTeacherSettings] = React.useState(false);
    const { uid, email, emailVerified, reload } = useUser();
    const teacherEmail = email;
    const [students, isLoadingStudents, reloadTeacherStudents] = useTeacherStudents(teacherEmail);
    const { hash } = useLocation();
    React.useEffect(() => {
        if (hash === '#opensettings') {
            setOpenTeacherSettings(true);
            //remove hash from url
            window.location.hash = '';
        }
    }, [hash, students])

    React.useEffect(() => {
        if (!openTeacherSettings && !isLoadingStudents && students.length === 0) {
            setOpenStudentSettings(true);
        }
    }, [students, isLoadingStudents, openTeacherSettings]);

    React.useEffect(() => {
        if (emailVerified) {
            reloadTeacherStudents();
        }
    }, [emailVerified, reloadTeacherStudents]);

    return (
        <>
            <Box component={"img"} src={logo} className={classes.logo} />
            <Box className={classes.root}>
                <Typography variant="h2" alignSelf={'center'} color="secondary" fontWeight="bold">Students</Typography>
                <Box display="flex" justifyContent="center" alignItems="center" gap={2} maxWidth="50vw" alignSelf="center">
                    <Button variant="outlined" size='large' onClick={() => setOpenTeacherSettings(true)}>
                        <SettingsIcon />
                        Teacher's Settings
                    </Button>
                </Box>

                <EmailVerificationMessage email={email} emailVerified={emailVerified} reload={reload} />

                <Box display="flex" gap={3} justifyContent="center" flexWrap="wrap" maxHeight="50vh" overflow="auto">
                    {students.map((student) => {
                        return (
                            <StudentDisplay student={student} key={student.id} view="teacher" />
                        )
                    })}
                </Box>

                <Button variant='contained' color="primary" size='large' className={classes.addStudentButton} onClick={() => { setOpenStudentSettings(true) }}>
                    <ControlPointIcon fontSize='large' />
                    <Typography variant="h6">New Student</Typography>
                </Button>
            </Box>

            <Button className={classes.signoutButton} size='large' onClick={logout}>
                <LogoutIcon fontSize='large' />
                <Typography variant="h5">Sign Out</Typography>
            </Button>

            <StudentSettings
                open={openStudentSettings}
                // TODO: close={() => setOpenStudentSettings(false)}
                setOpen={setOpenStudentSettings}
            />

            <TeacherSettings
                open={openTeacherSettings}
                setOpen={setOpenTeacherSettings}
                currentUser={{ uid, email, emailVerified, reload }}
            />
        </>
    )
}

export default TeacherDashboard;
