import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';

const Password = ({ defaultValue, showPasswordPolicy = false }) => {
    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <>
            <TextField id="password" label="Password" variant="outlined" fullWidth type={showPassword ? 'text' : 'password'} defaultValue={defaultValue}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }} />

            {showPasswordPolicy && <Typography variant="body2" color="error">
                Password must be between 6 and 30 characters long and contain at least one uppercase letter, one lowercase letter, and one number.
            </Typography>}
        </>


    )
}

export default Password;
