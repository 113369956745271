import React from 'react';

import { useUID, useUserEmail } from '../utils/firebase';
import Authentication from '../pages/parent/authentication';

const ParentLoginRequired = (props) => {
    const uid = useUID();
    const uemail = useUserEmail();
    const isSignedIn = !!uid || !!uemail;

    return (
        <>
            { isSignedIn?
                props.children
        : <Authentication />}
        </>
    )
}

export default ParentLoginRequired;