import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './useStyles';
import SettingsIcon from '@mui/icons-material/Settings';
import StudentSettings from './StudentSettings';

/**
 * 
 * @param {{
 * student: Student,
 * view: "teacher" | "student"
 * }} props 
 * @returns 
 */
const StudentDisplay = (props) => {
    const {
        student,
        view
    } = props;
    const classes = useStyles();
    const [openStudentSettings, setOpenStudentSettings] = React.useState(false);

    return (
        <>
            <Box className={classes.studentDisplay}
                onClick={() => {window.open(`/student/${student.id}?view=${view}`, "_self")}}>
                {view === "teacher" && <Button onClick={(e) => { e.stopPropagation(); setOpenStudentSettings(true)}} sx={{ alignSelf: "flex-end" }} className={classes.studentSettingButton}>
                    <SettingsIcon color='primary' />
                </Button>}
                <Box component="img" src={student.avatar} width={185}/>
                <Typography variant="h6">{student.name}</Typography>
            </Box>

            <StudentSettings open={openStudentSettings} setOpen={setOpenStudentSettings} student={student} />
        </>
    )
}

export default StudentDisplay;
