import React from 'react';
import { useStyles } from '../dashboard/useStyles';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useStudent, useUser } from '../../../utils/firebase';
import RecordDisplay from './RecordDisplay';
import StudentsDrawer from './StudentsDrawer';
import StudentLockScreen from '../../../components/LockScreen';
import EmailVerificationMessage from '../../../components/EmailVerificationMessage';

const StudentDashboard = () => {
    const logo = "/img/logo.svg";
    const classes = useStyles();
    const params = useParams();
    const studentID = params.studentID;
    const [student, , , ,reloadStudent] = useStudent(studentID);
    const { email, emailVerified, reload } = useUser();

    React.useEffect(() => {
        if (emailVerified) {
            reloadStudent();
        }
    }, [reloadStudent, emailVerified]);

    return (
        <StudentLockScreen>
            <Box component={"img"} src={logo} className={classes.logo} />

            <StudentsDrawer />

            <Box className={classes.studentDashboard}>
                <Box display="flex" flexDirection="column" flex={4}>
                    <Box component="img" src={student?.avatar} width={200} />
                    <Typography variant="h4" color="primary" fontWeight="bold" textAlign="center">{student?.name}</Typography>
                </Box>
                <EmailVerificationMessage emailVerified={emailVerified} email={email} reload={reload} />
                <Box flex={6} overflow="auto">
                    <RecordDisplay />
                </Box>
            </Box>
        </StudentLockScreen>
    );
}

export default StudentDashboard;
