import { Box, Button, Checkbox, Divider, FormControlLabel, Link, TextField, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './useStyles';
import { login, sendPasswordResetToEmail } from '../../../utils/firebase';
import Password from './password';

const SigninForm = () => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [showPasswordPolicy, setShowPasswordPolicy] = React.useState(false);
    const [isResetPwEmailSent, setIsResetPwEmailSent] = React.useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        setShowPasswordPolicy(false);
        setErrorMessage(null);
        setIsResetPwEmailSent(false);
        login(e.target.email.value, e.target.password.value, e.target.remember.checked)
            .catch((error) => {
                if (error.code === 'auth/password-does-not-meet-requirements') {
                    setShowPasswordPolicy(true);
                    setErrorMessage("Your " + error.message.split('[')[1].split(']')[0].split(',')[0]);
                }
                else {
                    setErrorMessage("Invalid email or password. Please try again.");
                }
            })
    }

    return (
        <Box className={classes.container}>
            <Typography variant="h4" className={classes.welcome}>Welcome!</Typography>
            <Typography variant="h6" alignSelf={"center"}>Sign in to your Teacher Account</Typography>
            <Divider className={classes.divider} />
            <form style={{ maxWidth: '500px', margin: 'auto' }} onSubmit={onSubmit}>
                <TextField
                    required
                    id="email"
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />

                <Password showPasswordPolicy={showPasswordPolicy} />

                {Boolean(errorMessage) &&
                    <Typography variant="body2" color="error">
                        {errorMessage}
                    </Typography>}

                {showPasswordPolicy && 
                    <Typography variant="body2" color="error">
                        <Link href='#' onClick={() => {
                            sendPasswordResetToEmail(document.getElementById('email').value)
                                .then(() => {
                                    setIsResetPwEmailSent(true);
                                    setErrorMessage(null);
                                });
                        }} color="inherit">Reset your password</Link> or try again.
                    </Typography>}

                {isResetPwEmailSent &&
                    <Typography variant="body2" color="success.dark">
                        A password reset email has been sent to your email address. Please check your email inbox.
                    </Typography>}

                <FormControlLabel control={<Checkbox id="remember" value="remember" color="primary" />} label="Remember me" />

                <Button type="submit" variant="contained" color="primary" className={`${classes.submit}`} fullWidth>
                    Sign In
                </Button>
            </form>
        </Box>
    )
}

export default SigninForm;