import { Box, Button, Typography } from "@mui/material";
import { sendEmailVerificationLink } from "../utils/firebase";
import React from "react";

const EmailVerificationMessage = ({ email, emailVerified, reload }) => {
    const [isEmailSent, setIsEmailSent] = React.useState(false);

    return (
        !emailVerified &&
        <Box display="flex" flexDirection="column">
            <Typography textAlign="center">
                Your account is not verified yet. Please check your email inbox and click the link to verify your email address.
            </Typography>
            <Box display="flex" gap={2} sx={{ width: 'fit-content', alignSelf: 'center' }} >
                <Button variant="contained" onClick={() => {
                    setIsEmailSent(false);
                    sendEmailVerificationLink().then(() => {
                        setIsEmailSent(true);
                    })
                }}>
                    Resend Email
                </Button>
                <Button onClick={() => reload()}>
                    Already Verified?
                </Button>
            </Box>
            {isEmailSent && <Typography textAlign="center" color="success.main">
                An email has been sent to {email}. Please check your inbox.
            </Typography>}
        </Box>
    )
}

export default EmailVerificationMessage;
