import { Box, Button, FormControlLabel, InputLabel, Modal, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './useStyles';
import { getDocsFromRefs, getRef, useStudent, useTeacherDescriptions, useUser } from '../../../utils/firebase';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import isEmail from 'validator/lib/isEmail';
import StudentDescriptionOptions from './StudentDescriptionOptions';
import { AVATARS } from '../../../utils/constants';

/**
 * 
 * @param {{
 * open: boolean,
 * setOpen: React.Dispatch<React.SetStateAction<boolean>>,
 * student: Student | null,
 * }} props 
 * @returns 
 */
const StudentSettings = (props) => {
    const {
        open,
        setOpen,
        student,
    } = props;
    const classes = useStyles();
    const [selectedAvatar, setSelectedAvatar] = React.useState(student?.avatar || AVATARS[0]);
    const { uid: teacherID, email: teacherEmail } = useUser();
    const [descriptions, ,] = useTeacherDescriptions(teacherID);
    const [error, setError] = React.useState({});
    const [studentDescriptions, setStudentDescriptions] = React.useState([]);
    const [, setStudent, deleteStudent,] = useStudent(student?.id || null);
    const [openMilestoneTooltip, setOpenMilestoneTooltip] = React.useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (e.target.additionalTeacher.value && !isEmail(e.target.additionalTeacher.value)) {
            setError({ ...error, additionalTeacher: 'Invalid email' });
            return;
        }
        if (e.target.parentEmail1.value && !isEmail(e.target.parentEmail1.value)) {
            setError({ ...error, parentEmail1: 'Invalid parent 1 email' });
            return;
        }
        if (e.target.parentEmail2.value && !isEmail(e.target.parentEmail2.value)) {
            setError({ ...error, parentEmail2: 'Invalid parent 2 email' });
            return;
        }

        const descriptionPaths = Array.from(document.querySelectorAll('input[name="description"]:checked')).map((input) => input.value);
        if (!student) { // create new student
            // const parent1 = await addDocument('parents', {
            //     email: e.target.parentEmail1.value,
            // });
            const parentIDs = [e.target.parentEmail1.value];
            if (e.target.parentEmail2.value) {
                // const parent2 = await addDocument('parents', {
                //     email: e.target.parentEmail2.value,
                // });
                parentIDs.push(e.target.parentEmail2.value);
            }
            setStudent({
                avatar: document.querySelector('[name="avatar"]:checked')?.value,
                name: e.target.studentName.value,
                parentEmails: [e.target.parentEmail1.value, ...(e.target.parentEmail2.value ? [e.target.parentEmail2.value] : [])],
                teacherEmails: [teacherEmail, ...(e.target.additionalTeacher.value ? [e.target.additionalTeacher.value] : [])],
                createdAt: new Date(),
                sweetMilestones: [parseInt(e.target.milestone1.value), parseInt(e.target.milestone2.value), parseInt(e.target.milestone3.value)].sort((a, b) => a - b),
                descriptionRefs: descriptionPaths.map((path) => getRef(path)),
            });
        }
        else {
            setStudent({
                ...student,
                avatar: document.querySelector('[name="avatar"]:checked')?.value,
                name: e.target.studentName.value || student.name,
                sweetMilestones: [parseInt(e.target.milestone1.value), parseInt(e.target.milestone2.value), parseInt(e.target.milestone3.value)].sort((a, b) => a - b),
                descriptionRefs: descriptionPaths.map((path) => getRef(path)),
                parentEmails: [e.target.parentEmail1.value, ...(e.target.parentEmail2.value ? [e.target.parentEmail2.value] : [])],
                teacherEmails: [teacherEmail, ...(e.target.additionalTeacher.value ? [e.target.additionalTeacher.value] : [])],
            })
        }
        setOpen(false);
    }

    React.useEffect(() => {
        if (student) {
            getDocsFromRefs(student?.descriptionRefs || []).then((descriptions) => {
                setStudentDescriptions(descriptions);
            });
        }
        else { // create new student default select all descriptions
            setStudentDescriptions(descriptions);
        }
    }, [student, descriptions]);

    return (
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className={classes.settings}>
                <Typography variant="h4" textAlign="center" fontWeight="bold">Student Settings</Typography>

                <form style={{ margin: 'auto' }} onSubmit={onSubmit}>
                    <Box display="flex" gap={2} mb={2}>
                        <Box className={classes.avatar} component="img" src={selectedAvatar} sx={{ width: '100px', height: '100px' }} />
                        <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                            <InputLabel>Avatar Selector</InputLabel>
                            <RadioGroup aria-label="avatar" name="avatar" defaultValue={student?.avatar || AVATARS[0]} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '2vh',
                                "& label": {
                                    margin: '0',
                                },
                                "& label > span:first-of-type": {
                                    display: 'none',
                                },
                                "& label:has(> .Mui-checked) img": {
                                    outline: '2px solid #19C0FF;',
                                    borderRadius: '10px',
                                }
                            }}>
                                {AVATARS.map((avatar, index) => (
                                    <FormControlLabel key={index} value={avatar} control={<Radio onClick={() => {
                                        setSelectedAvatar(avatar);
                                    }} />}
                                        label={<Box key={index} className={classes.avatar} component="img" src={avatar} sx={{ width: '70px', height: '70px' }} />} />
                                ))}
                            </RadioGroup>
                        </Box>
                    </Box>

                    <InputLabel htmlFor="studentName">Student Name *</InputLabel>
                    <TextField required id="studentName" variant="outlined" margin="normal" fullWidth sx={{ mt: 0, mb: 2 }} defaultValue={student?.name} />

                    <Box display="flex" gap={2} mb={2}>
                        <Box display="flex" flexDirection="column" gap={1} flex={1}>
                            <InputLabel htmlFor="parentEmail1">Parent 1</InputLabel>
                            <TextField id="parentEmail1" placeholder="email" variant="outlined" margin="normal" fullWidth sx={{ mt: 0 }}
                                error={error.parentEmail1 ? true : false} defaultValue={student?.parentEmails?.[0]} />
                        </Box>

                        <Box display="flex" flexDirection="column" gap={1} flex={1}>
                            <InputLabel htmlFor="parentEmail2">Parent 2 (optional)</InputLabel>
                            <TextField id="parentEmail2" placeholder="email" variant="outlined" margin="normal" fullWidth sx={{ mt: 0 }}
                                error={error.parentEmail2 ? true : false} defaultValue={student?.parentEmails?.[1]} />
                        </Box>
                    </Box>

                    <StudentDescriptionOptions
                        descriptions={descriptions.filter((description) => description.isVisible || description.isVisible === undefined)}
                        studentDescriptions={studentDescriptions}
                    />

                    <Box display="flex" flexDirection="row" mt={2} gap={2}>
                        <Box flex={2}>
                            <InputLabel htmlFor="additionalTeacher">Additional Teacher Email</InputLabel>
                            <TextField id="additionalTeacher" variant="outlined" margin="normal" fullWidth sx={{ mt: 0 }} onFocus={(e) => { e.target.select(); }}
                                error={error.additionalTeacher ? true : false}
                                defaultValue={student?.teacherEmails && student?.teacherEmails.filter((email) => email !== teacherEmail)[0]} />
                        </Box>
                        <Box flex={2}>
                            <InputLabel htmlFor="milestones" sx={{ display: 'flex', gap: 1 }}>
                                Sweet Milestones
                                <Tooltip open={openMilestoneTooltip} onClose={() => setOpenMilestoneTooltip(false)} leaveTouchDelay={0}
                                    title="Use milestones to reward the student after your chosen milestone number of sweets. Once they reach the milestone, new sweets with different looks and sounds will be awarded.">
                                    <Button onClick={() => setOpenMilestoneTooltip(true)} sx={{ p: 0, minWidth: 'auto' }}>
                                        <HelpOutlineIcon color='primary' fontSize='small' />
                                    </Button>
                                </Tooltip>
                            </InputLabel>
                            <Box display="flex" flexDirection="row" gap={1}>
                                <Box>
                                    <InputLabel htmlFor="milestone1">Milestone 1</InputLabel>
                                    <TextField defaultValue={student?.sweetMilestones?.[0] || 6} type='number' flex={1} id="milestone1" variant="outlined" margin="normal" fullWidth sx={{ mt: 0 }} />
                                </Box>
                                <Box>
                                    <InputLabel htmlFor="milestone2">Milestone 2</InputLabel>
                                    <TextField defaultValue={student?.sweetMilestones?.[1] || 10} type='number' flex={1} id="milestone2" variant="outlined" margin="normal" fullWidth sx={{ mt: 0 }} />
                                </Box>
                                <Box>
                                    <InputLabel htmlFor="milestone3">Milestone 3</InputLabel>
                                    <TextField defaultValue={student?.sweetMilestones?.[2] || 15} type='number' flex={1} id="milestone3" variant="outlined" margin="normal" fullWidth sx={{ mt: 0 }} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" mt={2}>
                        {student && <Button variant="outlined" color="primary" size='large' onClick={deleteStudent}>
                            <DeleteOutlineIcon />
                            Delete
                        </Button>}

                        <Box display="flex" flex={1} justifyContent="flex-end" gap={3}>
                            <Button variant="outlined" color="primary" size='large' onClick={() => setOpen(false)}>Cancel</Button>
                            <Button variant="contained" color="primary" type="submit" size='large'>{student ? "Save" : "Add"}</Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Modal>
    )
}

export default StudentSettings;