import { Box, Popover, TextField } from '@mui/material';
import EmojiPicker, { Emoji, EmojiStyle } from 'emoji-picker-react';
import React from 'react';
import { useStyles } from './useStyles';
import { useDescription, useUID } from '../../../utils/firebase';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

/**
 * 
 * @param {{
 *  description: Description,
 * }} props 
 * @returns 
 */
const EditableDescription = (props) => {
    const {
        description
    } = props;
    const classes = useStyles();
    const [emojiPickerAnchor, setEmojiPickerAnchor] = React.useState(null);
    const teacherID = useUID();
    const [, setDescription,,] = useDescription(teacherID, description.id);

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                <Box className={classes.emojiBox} onClick={(event) => setEmojiPickerAnchor(event.currentTarget)} sx={{ cursor: 'pointer'}}>
                    <Emoji unified={description.emoji} size={36} emojiStyle={EmojiStyle.NATIVE} />
                </Box>
                <TextField
                    variant="outlined"
                    sx={{ flex: 1 }}
                    defaultValue={description.label}
                    color='primary'
                    onBlur={(event) => setDescription({ ...description, label: event.target.value })}
                />
                {(description?.isVisible || description?.isVisible === undefined) ? (
                    <VisibilityIcon sx={{ cursor: 'pointer' }} fontSize='large' onClick={() => setDescription({ ...description, isVisible: false })} />
                ) : (
                    <VisibilityOffIcon sx={{ cursor: 'pointer' }} fontSize='large' onClick={() => setDescription({ ...description, isVisible: true })} />
                )}
            </Box>

            <Popover open={Boolean(emojiPickerAnchor)} anchorEl={emojiPickerAnchor} onClose={() => setEmojiPickerAnchor(null)}>
                <EmojiPicker emojiStyle={EmojiStyle.NATIVE}
                    style={{ alignSelf: "center" }}
                    onEmojiClick={(emojiObject, event) => { setDescription({ ...description, emoji: emojiObject.unified }); setEmojiPickerAnchor(null); }}
                    lazyLoadEmojis={true}
                />
            </Popover>
        </>
    )
}

export default EditableDescription;
