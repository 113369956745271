import { Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { HelpOutlineOutlined } from '@mui/icons-material';

const LockcodeField = (props) => {
    const {
        defaultLockCode,
        enableLockcode,
        setEnableLockCode,
    } = props;
    const [showLockCodeTooltip, setShowLockCodeTooltip] = React.useState(false);

    return (
        <Box display="flex" flexDirection="row" gap={2} flex={1}>
            <Button variant={enableLockcode ? 'outlined' : 'contained'} color='primary' onClick={() => setEnableLockCode(!enableLockcode)}
                sx={{
                    flex: 1,
                    '&.MuiButton-contained': {
                        textTransform: 'none',
                    },
                    '&.MuiButton-outlined': {
                        textTransform: 'none',
                    },
                }}>
                <Typography>Turn passcode {enableLockcode ? 'OFF' : 'ON'}</Typography>
            </Button>
            <Box flex={1}>
                <Tooltip className='lockcode-tooltip' title="A 4-digit code used to switch between student dashboards" leaveTouchDelay={0} placement='top-start'
                    onClose={(e) => setShowLockCodeTooltip(false)} open={showLockCodeTooltip}>
                    <div></div>
                </Tooltip>

                <TextField id="lockcode" type='number' inputMode='numeric' variant="outlined" fullWidth value={defaultLockCode} required
                    onBlur={(e) => { setShowLockCodeTooltip(false) }} disabled={!enableLockcode}
                    label={<Box display="inline-flex" gap={1}>
                        <Typography>Change passcode</Typography>
                        <Box onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); setShowLockCodeTooltip(!showLockCodeTooltip); }} padding="0px 7px">
                            <HelpOutlineOutlined />
                        </Box>
                    </Box>} />
            </Box>

        </Box>
    )
}

export default LockcodeField;
