import React from 'react';
import { useStyles } from '../pages/teacher/dashboard/useStyles';
import { Box } from '@mui/material';

const Background = () => {
    const imgBackground = "/img/background.svg";
    const classes = useStyles();
    const [backgroundWidth, backgroundHeight] = [1080, 761];
    const width = Math.min(backgroundWidth * backgroundHeight / window.innerHeight, window.innerWidth);

    return (
        <>
            <Box className={classes.background} width={width / 2}>
                <Box component={"img"} src={imgBackground} />
            </Box>

            <Box className={classes.background} width={width / 2} right={0}>
                <Box component={"img"} src={imgBackground} sx={{ float: 'right' }} />
            </Box>
        </>
    )
}

export default Background;