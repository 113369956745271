import { Box, Checkbox, InputLabel } from '@mui/material';
import React from 'react';
import { SOUR, SWEET } from '../../../utils/constants';
import { DescriptionDisplay } from './TeacherSettings';

/**
 * 
 * @param {{
 *  descriptions: Description[],
 *  studentDescriptions: Description[],
 * }} props 
 * @returns 
 */
const StudentDescriptionOptions = (props) => {
    const {
        descriptions,
        studentDescriptions,
    } = props;

    // merge descriptions and studentDescriptions
    // since descriptions are belong to the current teacher, studentDescriptions are belong to both the current teacher and the additonal teachers
    const mergedDescriptions = descriptions
        .concat(studentDescriptions.filter((studentDescription) => !descriptions.some((description) => description.id === studentDescription.id)));

    return (
        <Box display="flex" gap={2}>
            <Box flex={1} display="flex" gap={1} flexDirection="column">
                <InputLabel>Sweet Descriptions</InputLabel>
                {mergedDescriptions.filter((description) => description.type === SWEET).map((description) => (
                    // TODO: use path not id
                    <Box key={description.id} display="flex" alignItems="center">
                        <Checkbox name="description" value={description.path} defaultChecked={studentDescriptions.some((studentDescription) => studentDescription.id === description.id)} />
                        <DescriptionDisplay description={description} />
                    </Box>
                ))}
            </Box>

            <Box flex={1} display="flex" gap={1} flexDirection="column">
                <InputLabel>Sour Descriptions</InputLabel>
                {mergedDescriptions.filter((description) => description.type === SOUR).map((description) => (
                    <Box key={description.id} display="flex" alignItems="center">
                        <Checkbox name="description" value={description.path} defaultChecked={studentDescriptions.some((studentDescription) => studentDescription.id === description.id)} />
                        <DescriptionDisplay description={description} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default StudentDescriptionOptions;