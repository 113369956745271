import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './useStyles';
import { registerTeacher } from '../../../utils/firebase';
import Password from './password';
import LockcodeField from '../lockcodefield';

const SignupForm = () => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [enableLockcode, setEnableLockCode] = React.useState(true);
    const [showPasswordPolicy, setShowPasswordPolicy] = React.useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        setShowPasswordPolicy(false);
        setErrorMessage(null);
        registerTeacher(e.target.email.value, e.target.password.value, e.target.name.value, e.target.lockcode.value, enableLockcode)
            .catch((error) => {
                if (error.code === 'auth/password-does-not-meet-requirements') {
                    setShowPasswordPolicy(true);
                }
                else {
                    setErrorMessage('Invalid email or password');
                }
            })
            .then(() => {
                //add hash to url
                window.location.hash = '#opensettings';
            })
    }

    return (
        <Box className={classes.container}>
            <Typography variant="h4" className={classes.welcome}>Welcome!</Typography>
            <Typography variant="h6" alignSelf={"center"}>Create your Teacher Account</Typography>
            <Divider className={classes.divider} />
            <form onSubmit={onSubmit} style={{ maxWidth: '500px', margin: 'auto', display: 'flex', flexDirection: 'column', gap: '20px', width: "80%" }}>
                <TextField required id="name" label="Full Name" variant="outlined" fullWidth />

                <LockcodeField enableLockcode={enableLockcode} setEnableLockCode={setEnableLockCode} />

                <TextField required id="email" label="Email" variant="outlined" fullWidth />

                <Password showPasswordPolicy={showPasswordPolicy} />
                
                {Boolean(errorMessage) &&
                    <Typography variant="body2" color="error">
                        {errorMessage}
                    </Typography>}

                <Button type="submit" variant="contained" color="primary" className={`${classes.submit}`} fullWidth>
                    Sign Up
                </Button>


            </form>
        </Box>
    )
};

export default SignupForm;