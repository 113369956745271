import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: "50%",
            margin: `auto`,
            height: "100vh",
            display: "flex",
        },

        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            margin: 'auto',
            width: "100%",
            height: "80%"
        },

        leftBackground: {
            position: "fixed",
            height: "100vh",
            transform: "translateX(calc(-100% + 20vw))",
        },

        rightBackground: {
            position: "fixed",
            height: "100vh",
            right: 0,
            transform: "translateX(calc(100% - 20vw))",
        },

        flexColumn: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
            width: "10vw"
        },

        flexRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },

        boxShadow: {
            boxShadow: `0px 0px 10px -2px ${theme.palette.primary.main}`,
        },

        button: {
            borderRadius: "30px !important",
            // margin: `${theme.spacing(2)} 0 !important`,
            alignSelf: "center",
        },

        signUp: {
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
            }
        },

        welcome: {
            alignSelf: "center",
            color: theme.palette.secondary.main,
            fontWeight: "bold",
        },

        divider: {
            width: "40vw",
            border: 0,
            margin: `${theme.spacing(4)} 0 !important`,
            borderTop: `1px solid ${theme.palette.primary.main}`,
            alignSelf: "center",   
        },

        signIn: {
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: theme.palette.primary.dark,
            },
        },

        gap2: {
            gap: `${theme.spacing(4)} !important`,
        },

        gap20: {
            gap: `${theme.spacing(20)} !important`,
        },
        
        submit: {
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: theme.palette.primary.dark,
            },
            margin: `${theme.spacing(2)} 0 !important`,
        },

        backButton: {
            position: "absolute !important",
            top: "50px",
            left: "50px",
            width: "150px",
            textTransform: "none",
            fontWeight: "bold !important",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)",
            background: `${theme.palette.primary.contrastText} !important`,
            borderRadius: "30px !important",
        },
    })
);