import React, { useEffect } from 'react';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../utils/shareStates';
import { Alert, Modal } from '@mui/material';

const AlertMessage = () => {
    const [errorMessage, setErrorMessage] = ERROR_MESSAGE.useSharedState();
    const [successMessage, setSuccessMessage] = SUCCESS_MESSAGE.useSharedState();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (errorMessage || successMessage) {
            setOpen(true);
        }
    }, [errorMessage, successMessage]);

    return (
        <Modal open={open} onClose={() => { 
            setErrorMessage(null); 
            setSuccessMessage(null);
            setOpen(false); 
        }}
        sx={{
            '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
                backgroundColor: 'transparent',
            }
        }}>
            <>
                {errorMessage && (
                    <Alert severity="error" onClose={() => { setErrorMessage(null); setOpen(false); }}>
                        {errorMessage}
                    </Alert>)}
                
                {successMessage && (
                    <Alert severity="success" onClose={() => { setErrorMessage(null); setOpen(false); }}>
                        {successMessage}
                    </Alert>)}
            </>
        </Modal>
    )
}

export default AlertMessage;
