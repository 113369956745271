import React from 'react';
import { useStyles } from '../../teacher/dashboard/useStyles';
import { Box, Button, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout, useParentsStudents, useUserEmail } from '../../../utils/firebase';
import StudentDisplay from '../../teacher/dashboard/StudentDisplay';

const ParentDashboard = () => {
    const logo = "/img/logo.svg";
    const classes = useStyles();
    const parentEmail = useUserEmail();
    const [students, ] = useParentsStudents(parentEmail);

    return (
        <>
            <Box component={"img"} src={logo} className={classes.logo} />
            <Box className={classes.root}>
                <Typography variant="h2" alignSelf={'center'} color="secondary" fontWeight="bold">Parent Dashboard</Typography>

                <Box display="flex" justifyContent="center" alignItems="center" gap={2} maxWidth="50vw" alignSelf="center">
                    <Button variant="outlined" size='large' onClick={() => {}}>
                        <SettingsIcon />
                        Parent's Settings
                    </Button>
                </Box>

                <Box display="flex" gap={3} justifyContent="center" flexWrap="wrap" maxHeight="50vh" overflow="auto">
                    {students.map((student) => {
                        return (
                            <StudentDisplay student={student} key={student.id} view="parent" />
                        )
                    })}
                </Box>
            </Box>
            <Button className={classes.signoutButton} size='large' onClick={logout}>
                <LogoutIcon fontSize='large' />
                <Typography variant="h5">Sign Out</Typography>
            </Button>
        </>
    )
}

export default ParentDashboard;
