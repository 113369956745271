import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from '../../teacher/authentication/useStyles';
import Password from '../../teacher/authentication/password';
import { registerParent } from '../../../utils/firebase';

const SignupForm = () => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = React.useState(null);

    const onSubmit = (e) => {
        e.preventDefault();
        setErrorMessage(null);
        registerParent(e.target.email.value, e.target.password.value, e.target.name.value)
            .catch((error) => {
                setErrorMessage("Invalid email or password");
            })
    }

    return (
        <Box className={classes.container}>
            <Typography variant="h4" className={classes.welcome}>Welcome!</Typography>
            <Typography variant="h6" alignSelf={"center"}>Create your Parent Account</Typography>
            <Divider className={classes.divider} />
            <form onSubmit={onSubmit} style={{ maxWidth: '500px', margin: 'auto', display: 'flex', flexDirection: 'column', gap: '20px', width: "80%" }}>
                <TextField required id="name" label="Full Name" variant="outlined" fullWidth />

                <TextField required id="email" label="Email" variant="outlined" fullWidth />

                <Password />

                <Typography variant="body2" color="error" style={{ textAlign: 'center' }}>
                    {Boolean(errorMessage) && errorMessage}
                </Typography>

                <Button type="submit" variant="contained" color="primary" className={`${classes.submit}`} fullWidth>
                    Sign Up
                </Button>
            </form>
        </Box>
    )
}

export default SignupForm;
