import { Box, Collapse, Typography } from '@mui/material';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import React from 'react';
import { useStyles } from '../dashboard/useStyles';
import { AVATARS, SOUR, SWEET } from '../../../utils/constants';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { getHash } from '../../../utils/common';
import { RECORD_DATE } from '../../../utils/shareStates';

/**
 * 
 * @param {{
 * index: number,
 * description: {Description},
 * type: string,
 * records: Array<{Record}>,
 * setRecord: (record: {Record}, id?: string) => void
 * onEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void
 * canAnimate: boolean
 * isRandomAvatar: boolean
 * canDelete: boolean
 * canEdit: boolean
 * }} props
 * @returns 
 */
const RecordDescription = (props) => {
    const {
        index,
        description,
        type,
        records,
        setRecord,
        onEdit,
        canAnimate,
        isRandomAvatar,
        canDelete,
        canEdit
    } = props;
    const classes = useStyles();
    const [selectedDescriptionAnchorEl, setSelectedDescriptionAnchorEl] = React.useState(null);
    const [selectedDescription, setSelectedDescription] = React.useState(null);
    const [selectedDescriptions, setSelectedDescriptions] = React.useState({});
    const [date,] = RECORD_DATE.useSharedState();
    const removeDescription = () => {
        if (records.length === 0 || !selectedDescriptionAnchorEl) return;
        setRecord({
            ...records[0],
            descriptions: records[0].descriptions.filter((_, _index) => index !== _index)
        });
        setSelectedDescriptionAnchorEl(null);
    }
    const randomAvatar = AVATARS[getHash(date) % AVATARS.length];

    return (
        <>
            <Box className={classes.recordDescription} alignSelf={type === SWEET ? 'flex-end' : 'flex-start'} gap={2} flexDirection={type === SWEET ? 'row-reverse' : 'row'}
                onClick={(e) => { setSelectedDescription(description); setSelectedDescriptions({ ...selectedDescriptions, [index]: selectedDescriptions[index] ? false : true }); setSelectedDescriptionAnchorEl(e.currentTarget); }}>
                {description && description.type === type && (
                    <>
                        {isRandomAvatar ?
                            <Box component="img" src={randomAvatar} width={50} /> :
                            <Box className={`${canAnimate ? classes.shake : ''}`} minWidth={50} component="img" src={type === SOUR ? '/img/sour_lollipop.svg' : '/img/sweet_lollipop.svg'} />}
                        <Box className={`${classes.recordEmoji} ${type === SWEET ? classes.backgroundSweet : classes.backgroundSour}`}>
                            <Emoji id={type} unified={description.emoji} size={24} emojiStyle={EmojiStyle.NATIVE} />
                        </Box>
                        <Collapse in={description.label && selectedDescriptions[index]} easing="ease-in-out"
                            children={<Box className={`${classes.recordDescriptionEditor}`} flexDirection={type === SWEET ? 'row-reverse' : 'row'} gap={1}>
                                <Typography variant="h6" className={type === SOUR ? classes.recordSourDescriptionText : classes.recordSweetDescriptionText}>{description.label}</Typography>
                                {canEdit && <EditOutlinedIcon onClick={(e) => onEdit(e, index)} fontSize='large' />}
                                {canDelete && <DeleteOutlinedIcon onClick={(e) => removeDescription(selectedDescription)} fontSize="large" />}
                            </Box>} />
                    </>
                )}
            </Box>
        </>

    )
}

export default RecordDescription;
