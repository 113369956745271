import { Box } from '@mui/material';
import { Emoji } from 'emoji-picker-react';
import React from 'react';
import { useStyles } from '../dashboard/useStyles';

/**
 * 
 * @param {{
 *  descriptions: Array<{Description}>
 * }} props 
 * @returns 
 */
const AddDescriptionButton = (props) => {
    const {
        descriptions
    } = props;
    const classes = useStyles();

    return (
        <Box className={`${classes.recordDescription} ${classes.addDescriptionButton}`}>
            {descriptions.slice(0, 4).map((description, index) => (
                <Emoji key={index} unified={description.emoji} size={20} />
            ))}
        </Box>
    )
}

export default AddDescriptionButton;
