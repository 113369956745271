import React from 'react';

import Authentication from '../pages/teacher/authentication';
import { useTeacherPrivate, useUser } from '../utils/firebase';
import { CURRENT_URL } from '../utils/constants';

const TeacherLoginRequired = (props) => {
    const currentUser = useUser();
    const isSignedIn = !!currentUser;
    const teacherID = currentUser?.uid;
    const [teacherPrivate] = useTeacherPrivate(teacherID);

    React.useEffect(() => {
        const currentUrl = window.localStorage.getItem(CURRENT_URL);
		if (currentUrl && currentUrl !== window.location.href && !!teacherPrivate?.lockcodeEnabled) {
			window.location.href = currentUrl;
		}
        if (teacherPrivate && !teacherPrivate?.lockcodeEnabled) {
            window.localStorage.removeItem(CURRENT_URL);
        }
    }, [teacherPrivate]);

    return (
        <>
            {isSignedIn ?
                props.children
                : <Authentication />}
        </>
    )
}

export default TeacherLoginRequired;
