export const palette = {
    primary: {
        main: "#19C0FF",
        dark: "#0E86D4",
        light: "#19C0FF26",
        contrastText: "#fff",
    },
    secondary: {
        main: "#29E98C !important",
        dark: "#1DBF6C !important",
        light: "#B2F7D4 !important",
        contrastText: "#fff !important",
    },
    sour: {
        main: "#F82B60",
        dark: "#00A000",
    },
    sweet: {
        main: "#29E98C",
        dark: "#C00030",
    },
    success: {
        main: "#29E98C",
        dark: "#1DBF6C",
        light: "#B2F7D4",
        contrastText: "#fff",
    },
}
