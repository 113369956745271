import { Box, Button, InputLabel, Typography } from "@mui/material";
import React from "react";
import EditableDescription from "./EditableDescription";
import { SOUR, SWEET } from "../../../utils/constants";
import NewDescriptionForm from "./NewDescriptionForm";
import ControlPointIcon from '@mui/icons-material/ControlPoint';

/**
 * 
 * @param {{
 * descriptions: Description[]
 * teacherID: string
 * }} props 
 */
export const EditableDescriptionList = (props) => {
    const {
        descriptions,
        teacherID
    } = props;

    const [openNewDescriptionForm, setOpenNewDescriptionForm] = React.useState(false);
    const [newDescriptionType, setNewDescriptionType] = React.useState(SWEET);
    
    return (
        <>
            <Box display="flex" justifyContent="space-between" gap={2} mb={3}>
                <Box flex={1} display="flex" gap={1} flexDirection="column">
                    <InputLabel>Sweet Descriptions</InputLabel>
                    {descriptions
                    .filter((description) => description.type === SWEET)
                    // sort by createdAt ascending with undefined first
                    .sort((a, b) => (a.createdAt === undefined) ? -1 : (b.createdAt === undefined) ? 1 : (a.createdAt > b.createdAt) ? 1 : -1)
                    .map((description) => (
                        <EditableDescription key={description.id} description={description} />
                    ))}
                    <Button variant="outlined" fullWidth onClick={() => { setOpenNewDescriptionForm(true); setNewDescriptionType(SWEET) }}>
                        <ControlPointIcon />
                        <Typography variant='body'>Add description</Typography>
                    </Button>
                </Box>

                <Box flex={1} display="flex" gap={1} flexDirection="column">
                    <InputLabel>Sour Descriptions</InputLabel>
                    {descriptions
                    .filter((description) => description.type === SOUR)
                    .sort((a, b) => (a.createdAt === undefined) ? -1 : (b.createdAt === undefined) ? 1 : (a.createdAt > b.createdAt) ? 1 : -1)
                    .map((description) => (
                        <EditableDescription key={description.id} description={description} />
                    ))}
                    <Button variant="outlined" fullWidth onClick={() => { setOpenNewDescriptionForm(true); setNewDescriptionType(SOUR) }}>
                        <ControlPointIcon />
                        <Typography variant='body'>Add description</Typography>
                    </Button>
                </Box>
            </Box>

            {openNewDescriptionForm && <NewDescriptionForm
                type={newDescriptionType}
                open={openNewDescriptionForm}
                setOpen={setOpenNewDescriptionForm}
                teacherID={teacherID}
            />}
        </>

    )
}
