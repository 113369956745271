import { Box, MenuItem } from '@mui/material';
import React from 'react';
import { DescriptionDisplay } from '../dashboard/TeacherSettings';

/**
 * 
 * @param {{
 * descriptions: Description[]
 * setDescription: (description: Description) => void
 * }} props 
 * @returns 
 */
const EditDescription = (props) => {
    const {
        descriptions,
        setDescription,
    } = props;

    return (
        <Box>
            {descriptions.map((description, index) => (
                <MenuItem key={index} onClick={() => setDescription(description)}>  
                    <DescriptionDisplay description={description} />
                </MenuItem>
            ))}
        </Box>
    )
}

export default EditDescription;