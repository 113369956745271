import { createTheme } from "@mui/material/styles";
import { palette } from "./palette";
import { spacing } from "./spacing";
import { breakpoints } from "./breakpoints";

export const theme = createTheme();

theme.typography = {
    ...theme.typography,
    body2: {
        ...theme.typography.body2,
        fontSize: '0.7rem',
    }
    // ...typography
}
theme.palette = {
    ...theme.palette,
    ...palette
}
theme.spacing = spacing
theme.breakpoints = {
    ...theme.breakpoints,
    ...breakpoints
}
