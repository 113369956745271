import React from 'react';
import { useStyles } from '../../teacher/authentication/useStyles';
import { Box, Button, Checkbox, Divider, FormControlLabel, TextField, Typography } from '@mui/material';
import Password from '../../teacher/authentication/password';
import { login } from '../../../utils/firebase';

const SigninForm = () => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = React.useState(null);

    const onSubmit = (e) => {
        e.preventDefault();
        setErrorMessage(null);
        login(e.target.email.value, e.target.password.value, e.target.remember.checked)
            .catch(() => {
                setErrorMessage("Invalid email or password. Please try again.");
            })
    }

    return (
        <Box className={classes.container}>
            <Typography variant="h4" className={classes.welcome}>Welcome!</Typography>
            <Typography variant="h6" alignSelf={"center"}>Sign in to your Parent Account</Typography>
            <Divider className={classes.divider} />
            <form style={{ maxWidth: '500px', margin: 'auto' }} onSubmit={onSubmit}>
                <TextField
                    required
                    id="email"
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />

                <Password />

                <FormControlLabel control={<Checkbox id="remember" value="remember" color="primary" />} label="Remember me" />

                <Typography variant="body2" color="error" style={{ textAlign: 'center' }}>
                    {Boolean(errorMessage) && errorMessage}
                </Typography>

                <Button type="submit" variant="contained" color="primary" className={`${classes.submit}`} fullWidth>
                    Sign In
                </Button>
            </form>
        </Box>
    )
}

export default SigninForm;
