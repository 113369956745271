import { useStyles } from "./useStyles";
import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import SignupForm from "./SignupForm";
import SigninForm from "./SigninForm";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeScreen from "../../../components/HomeScreen";

const Authentication = () => {
    const classes = useStyles();
    const [openSignIn, setOpenSignIn] = React.useState(false);
    const [openSignUp, setOpenSignUp] = React.useState(false);

    return (
        <>
            <Box className={classes.root}>
                {
                    openSignUp ?
                        <SignupForm /> :
                        openSignIn ?
                            <SigninForm />
                            :
                            <HomeScreen
                                setOpenSignIn={setOpenSignIn}
                                setOpenSignUp={setOpenSignUp}
                            />
                }
            </Box>
            {(openSignIn || openSignUp) &&
                <Button className={classes.backButton} size='large' onClick={() => { setOpenSignIn(false); setOpenSignUp(false) }}>
                    <ArrowBackIosIcon fontSize='medium' />
                    <Typography variant="h5">Back</Typography>
                </Button>}
        </>
    )
}

export default Authentication;
