import React from 'react';
import LockScreen from 'react-lock-screen';
import { LOCK } from '../utils/shareStates';
import { Button, TextField } from '@mui/material';
import { useStyles } from '../pages/teacher/dashboard/useStyles';
import { useTeacherPrivate, useUID } from '../utils/firebase';
import { CURRENT_URL } from '../utils/constants';

const StudentLockScreen = (props) => {
    const [lock, setLock] = LOCK.useSharedState();
    const classes = useStyles();
    const teacherID = useUID();
    const [teacherPrivate, ,] = useTeacherPrivate(teacherID);
    const [error, setError] = React.useState(null);
    console.log(teacherPrivate);

    const askLockcodePopup = setlock => {
        return (
            <form className={classes.askLockcodePopup}
                onSubmit={e => {
                    e.preventDefault();
                    setError(null);
                    const lockcode = e.target.lockcode.value;
                    if (lockcode === teacherPrivate.lockcode) {
                        setlock(false);
                        setLock(false);
                        window.localStorage.removeItem(CURRENT_URL);
                    }
                    else {
                        setError('Wrong passcode');
                    }
                }}>
                <TextField label="Passcode" variant="outlined" type="password" autoFocus name="lockcode" error={Boolean(error)} helperText={error} />
                <Button variant="contained" color='primary' size='large' type='submit'>Unlock</Button>
            </form>
        )
    }

    React.useEffect(() => {
        if (!!teacherPrivate?.lockcodeEnabled) {
            if (!!window.localStorage.getItem(CURRENT_URL) && window.localStorage.getItem(CURRENT_URL) !== window.location.href) {
                window.location.href = window.localStorage.getItem(CURRENT_URL);
            }
            else {
                window.localStorage.setItem(CURRENT_URL, window.location.href);
            }
        }
    }, [teacherPrivate?.lockcodeEnabled])

    return (lock ?
        <LockScreen timeout={0} ui={askLockcodePopup}>
            {props.children}
        </LockScreen> : props.children
    )
}

export default StudentLockScreen;
