import React from 'react';
import { useParams } from 'react-router-dom';
import { getDocu, useRecord, useRecordByDate, useStudent } from '../../../utils/firebase';
import { Box, Button, Popover, Typography } from '@mui/material';
import { useStyles } from '../dashboard/useStyles';
import { SOUR, SWEET } from '../../../utils/constants';
import AddDescription from './AddDescription';
import { RECORD_DATE } from '../../../utils/shareStates';
import AddDescriptionButton from './AddDescriptionButton';
import RecordDescription from './RecordDescription';
import EditDescription from './EditDescription';
import moment from 'moment/moment';

const RecordDisplay = () => {
    const params = useParams();
    const studentID = params.studentID;
    const [date,] = RECORD_DATE.useSharedState();
    const [records, ,] = useRecordByDate(studentID, date.format('YYYY-MM-DD'));
    const [record, setRecord, ,] = useRecord(studentID, records[0]?.id || null);
    const [student, setStudent, ,] = useStudent(studentID);
    const recordDescriptions = record?.descriptions || [];
    const classes = useStyles();
    const isToday = React.useMemo(() => moment().isSameOrBefore(date.$d, 'day'), [date]);
    const [ms1, ms2, ms3] = isToday ? (student?.sweetMilestones || [6, 10, 15]).map((milestone) => parseInt(milestone) || 6)
        : (record?.sweetMilestones || student?.sweetMilestones || [6, 10, 15]).map((milestone) => parseInt(milestone) || 6);
    const numLollipop = recordDescriptions ? (recordDescriptions.length < ms1 ? ms1 : recordDescriptions.length < ms2 ? ms2 : ms3) : 6;
    const [addDescriptionAnchorEl, setAddDescriptionAnchorEl] = React.useState(null);
    const [addDescriptionType, setAddDescriptionType] = React.useState(null);
    const [studentDescriptions, setStudentDescriptions] = React.useState([]);
    const sourSound = new Audio('/audio/sour.mp3');
    const sweetSound = new Audio('/audio/sweet.mp3');
    const [editDescriptionAnchorEl, setEditDescriptionAnchorEl] = React.useState(null);
    const [editDescriptionIndex, setEditDescriptionIndex] = React.useState(null);
    const query = new URLSearchParams(window.location.search);
    const view = query.get('view');
    const teacherView = view === 'teacher';

    React.useEffect(() => {
        if (student?.descriptionRefs) {
            Promise.all(student.descriptionRefs.map(async (ref) => (await getDocu(ref))))
                .then((descriptions) => setStudentDescriptions(descriptions));
        }
    }, [student]);

    React.useEffect(() => {
        if ((isToday && student?.sweetMilestones && record && JSON.stringify(record?.sweetMilestones) !== JSON.stringify(student.sweetMilestones))
        || (!isToday && student?.sweetMilestones && record && !record?.sweetMilestones)) {
        setRecord({
            sweetMilestones: student.sweetMilestones,
        });
        }
    }, [record, setRecord, student, isToday]);

    const addDescription = (description) => {
        if (records.length === 0) {
            setRecord({
                date: date.format('YYYY-MM-DD'),
                studentID: studentID,
                descriptions: [description],
            });
        }
        else {
            const currentRecord = records[0];
            if (currentRecord.id) delete currentRecord.id;
            if (currentRecord.path) delete currentRecord.path;
            setRecord({
                ...currentRecord,
                descriptions: [...records[0].descriptions, description],
            });
        }
        if (description.type === SOUR) sourSound.play();
        else sweetSound.play();

        setAddDescriptionAnchorEl(null);
    }

    const editDescription = (description) => {
        setRecord({
            ...records[0],
            descriptions: records[0].descriptions.map((d, i) => i === editDescriptionIndex ? description : d)
        });
        setEditDescriptionAnchorEl(null);
    }

    const onEdit = (e, index) => {
        e.preventDefault(); e.stopPropagation();
        setEditDescriptionIndex(index);
        setEditDescriptionAnchorEl(e.currentTarget);
    }

    const canAnimate = (index) => {
        // from the ms1 index sweet description, the first sweet description can be animated
        if (recordDescriptions[index].type === SOUR) return false;
        const sweetIndex = recordDescriptions.slice(0, index).filter((d) => d.type === SWEET).length;
        return sweetIndex >= ms1;
    }

    const isRandomAvatar = (index) => {
        if (recordDescriptions[index].type === SOUR) return false;
        const sweetIndex = recordDescriptions.slice(0, index).filter((d) => d.type === SWEET).length;
        return sweetIndex >= ms2;
    }

    return (
        <>
            <Box className={classes.recordDisplay}>
                <Box flex={1}>
                    {recordDescriptions.map((r, index) => (
                        <RecordDescription key={index} index={index} description={r} type={SWEET} records={records} setRecord={setRecord} canEdit={teacherView}
                            onEdit={onEdit} canAnimate={canAnimate(index)} isRandomAvatar={isRandomAvatar(index)} canDelete={index === recordDescriptions.length - 1 && teacherView} />
                    ))}
                    {recordDescriptions.length < numLollipop && <Button onClick={(e) => {
                        setAddDescriptionType(SWEET);
                        setAddDescriptionAnchorEl(e.currentTarget);
                    }} sx={{ width: 'fit-content', marginTop: '-10px', alignSelf: 'flex-end' }}>
                        {teacherView && <AddDescriptionButton descriptions={studentDescriptions.filter((d) => d.type === SWEET)} />}
                    </Button>}
                </Box>

                <Box>
                    {Array(numLollipop).fill().map((_, i) => (
                        <Box key={i + 1} alignSelf="center" display="flex" justifyContent="center">
                            <Typography variant="h4" color="white" alignSelf="center">{i + 1}</Typography>
                        </Box>
                    ))}
                </Box>

                <Box flex={1}>
                    {recordDescriptions.map((r, index) => (
                        <RecordDescription key={index} index={index} description={r} type={SOUR} records={records} setRecord={setRecord} canEdit={teacherView}
                            onEdit={onEdit} canAnimate={false} isRandomAvatar={false} canDelete={index === recordDescriptions.length - 1 && teacherView} />
                    ))}
                    {recordDescriptions.length < numLollipop && <Button onClick={(e) => {
                        setAddDescriptionType(SOUR);
                        setAddDescriptionAnchorEl(e.currentTarget);
                    }} sx={{ width: 'fit-content', marginTop: '-10px', alignSelf: 'flex-start' }}>
                        {teacherView && <AddDescriptionButton descriptions={studentDescriptions.filter((d) => d.type === SOUR)} />}
                    </Button>}
                </Box>
            </Box>

            <Popover open={Boolean(addDescriptionAnchorEl)} anchorEl={addDescriptionAnchorEl} onClose={() => setAddDescriptionAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: addDescriptionType === SOUR ? 'left' : 'right' }}>
                <AddDescription
                    descriptions={studentDescriptions}
                    addDescription={addDescription} 
                    type={addDescriptionType}
                    setStudent={setStudent} />
            </Popover>

            <Popover open={Boolean(editDescriptionAnchorEl)} anchorEl={editDescriptionAnchorEl} onClose={() => setEditDescriptionAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: editDescription.type === SOUR ? 'left' : 'right' }}>
                <EditDescription
                    descriptions={studentDescriptions.filter((description) => description.type === recordDescriptions[editDescriptionIndex]?.type)}
                    setDescription={editDescription} />
            </Popover>
        </>

    );
}

export default RecordDisplay;