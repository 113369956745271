import { Box, Button, MenuItem, Modal, Typography } from '@mui/material';
import React from 'react';
import { DescriptionDisplay } from '../dashboard/TeacherSettings';
import { getRef, useTeacherDescriptions, useUser } from '../../../utils/firebase';
import StudentDescriptionOptions from '../dashboard/StudentDescriptionOptions';
import { useStyles } from './useStyles';
import { EditableDescriptionList } from '../dashboard/EditableDescriptionList';

/**
 * 
 * @param {{
 * descriptions: Description[]
 * addDescription: (description: Description) => void
 * setStudent: (student: Student) => void
 * type: string
 * }} props 
 * @returns 
 */
const AddDescription = (props) => {
    const {
        descriptions,
        addDescription,
        setStudent,
        type
    } = props;
    const { uid: teacherID } = useUser();
    const [teacherDescriptions, ,] = useTeacherDescriptions(teacherID);
    const [openAddMore, setOpenAddMore] = React.useState(false);
    const [openShowAll, setOpenShowAll] = React.useState(false);
    const classes = useStyles();

    const addMoreDescription = () => {
        const descriptionPaths = Array.from(document.querySelectorAll('input[name="description"]:checked')).map((input) => input.value);
        setStudent({
            descriptionRefs: descriptionPaths.map((path) => getRef(path)),
        });
        setOpenAddMore(false);
    }

    return (
        <>
            <Box>
                {descriptions
                .filter((description) => description.type === type)
                .map((description, index) => (
                    <MenuItem key={index} onClick={() => {
                        if (description.id) delete description.id;
                        if (description.path) delete description.path; 
                        addDescription(description)
                    }}>
                        <DescriptionDisplay description={description} />
                    </MenuItem>)
                )}
                <MenuItem key={-1} onClick={() => setOpenAddMore(true)}>
                    <Typography variant="body1" color="textSecondary">
                        Add more...
                    </Typography>
                </MenuItem>
            </Box>

            <Modal open={openAddMore} onClose={() => setOpenAddMore(false)}>
                <Box className={classes.addMoreModal}>
                    <StudentDescriptionOptions
                        descriptions={teacherDescriptions.filter((description) => description.isVisible || description.isVisible === undefined)}
                        studentDescriptions={descriptions} />

                    <Box className={classes.addMoreModalButtons}>
                        <Button variant="outlined" onClick={() => {setOpenShowAll(true)}}>
                            SHOW ALL
                        </Button>
                        <Button onClick={addMoreDescription}>
                            DONE
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal open={openShowAll} onClose={() => setOpenShowAll(false)}>
                <Box className={classes.addMoreModal}>
                    <EditableDescriptionList
                        descriptions={teacherDescriptions}
                        teacherID={teacherID}
                    />
                    <Button onClick={() => setOpenShowAll(false)}>
                        DONE
                    </Button>
                </Box>
            </Modal>
        </>

    )
};

export default AddDescription;
