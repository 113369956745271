import { Autocomplete, Box, Button, Dialog, DialogTitle, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';

// copied from capacitor.config.js
const allowNavigation = [
    // prod
    "app.cleverpop.com.au",
    // test
    "cleverpop.scaleupconsulting.com.au",
];

const TesterSettings = () => {
    const numClicksTesterSettings = useRef(0);
    const [isOpen, setIsOpen] = useState(false);
    const onClickSecret = () => {
        ++numClicksTesterSettings.current;
        if (numClicksTesterSettings.current >= 10) {
            numClicksTesterSettings.current = 0;
            setIsOpen(true);
        }
    };
    const onCloseDialog = (_, reason) => {
        // in case someone taps too many times, don't close the dialog while they are trying to open it
        if (reason !== 'backdropClick')
            setIsOpen(false);
    };

    return <>
        <Box style={{ position: 'fixed', top: 0, left: '50%', transform: 'translateX(-50%)', height: 48, width: 48 }}
            onClick={onClickSecret} />
        <Dialog onClose={onCloseDialog} open={isOpen} fullWidth={true}>
            <DialogTitle>Tester Settings</DialogTitle>
            <Autocomplete options={allowNavigation.map(host => `https://${host}/teacher`)}
                defaultValue={window.location.toString()}
                renderInput={(params) => <TextField {...params} label="URL" />}
                onChange={(_, value) => window.location = value}
            />
            <Button onClick={onCloseDialog}>Cancel</Button>
        </Dialog>
    </>
};

export default TesterSettings;