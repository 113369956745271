import { SharedStateTarget } from "@scaleup-consulting/use-shared-state";
import dayjs from "dayjs";

export const RECORD_DATE = new SharedStateTarget(dayjs(new Date().setHours(0, 0, 0, 0)));

export const ERROR_MESSAGE = new SharedStateTarget(null);

export const SUCCESS_MESSAGE = new SharedStateTarget(null);

export const LOCK = new SharedStateTarget(false);

export const DIRECT_URL = new SharedStateTarget(null);