import React, { useState, useEffect, useMemo } from "react";
import { initializeApp } from "firebase/app";
import {
	getAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	updateEmail,
	updatePassword,
	setPersistence,
	browserLocalPersistence,
	browserSessionPersistence,
	sendEmailVerification,
	reload,
	getIdToken,
	sendPasswordResetEmail,
} from "firebase/auth";
import {
	doc,
	getFirestore,
	query,
	setDoc,
	collection,
	where,
	onSnapshot,
	addDoc,
	deleteDoc,
	getDoc,
	orderBy,
	documentId,
	Timestamp,
} from "firebase/firestore";
import {
	getStorage,
	ref,
	getDownloadURL,
	uploadBytes,
	deleteObject,
} from "firebase/storage";
import { get, set, remove, init } from "./localStorage";
import { firebaseConfig } from "./firebaseConfig";
import { DEFAULT_DESCRIPTIONS } from "./constants";

// initialization of firebase app
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();
const auth = getAuth();

// initialization of local storage
const UID = "cleverpop-uid";
const UEMAIL = "cleverpop-email";
const USER = "cleverpop-user";
init(UID, undefined);
init(UEMAIL, undefined);
init(USER, undefined);

export const registerTeacher = async (email, password, name, lockcode, enableLockcode) => {
	try {
		const user = await createUserWithEmailAndPassword(auth, email, password);
		setDoc(doc(db, "teachers_public", user.user.uid), {
			name: name,
		});
		setDoc(doc(db, "teachers_private", user.user.uid), {
			lockcode: lockcode,
			lockcodeEnabled: enableLockcode,
		});
		sendEmailVerification(user.user, {
			url: window.location.href,
		});

		return addDetaultDescriptions(user.user.uid).then(() => {
			return signInWithEmailAndPassword(auth, email, password);
		});
	}
	catch (error) {
		console.log("adding default descriptions when registering teacher");
		console.log(email);
		throw error;
	}
};

export const registerParent = async (email, password, name) => {
	try {
		const user = await createUserWithEmailAndPassword(auth, email, password);
		setDoc(doc(db, "parents", user.user.uid), {
			name: name,
		});
		return signInWithEmailAndPassword(auth, email, password);
	}
	catch (error) {
		throw error;
	}
}

export const addDetaultDescriptions = async (uid) => {
	try {
		return Promise.all(
			DEFAULT_DESCRIPTIONS.map(async (description) => {
				return addDoc(collection(db, "teachers_public", uid, "descriptions"), description);
			})
		);
	}
	catch (error) {
		throw error;
	}
}

export const login = (email, password, remember) => {
	try {
		const currentUser = auth.currentUser;
		if (currentUser) {
			return currentUser;
		}
		return setPersistence(auth, remember ? browserLocalPersistence : browserSessionPersistence)
			.then(() => signInWithEmailAndPassword(auth, email, password))
			.catch((error) => {
				throw error;
			});
	}
	catch (error) {
		throw error;
	}
};

export const logout = () => {
	return auth.signOut().then(() => {
		window.indexedDB.deleteDatabase("firebaseLocalStorageDb");
		// delete all cookies
		const cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf("=");
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}
	});
}

export const sendEmailVerificationLink = () => {
	if (!auth.currentUser) {
		throw new Error("No user signed in");
	}
	return sendEmailVerification(auth.currentUser, {
		url: window.location.href,
	});
}

export const reloadEmailVerified = async () => {
	return reload(auth.currentUser)
		.then(() => getIdToken(auth.currentUser, true))
		.then(() => auth.currentUser.emailVerified);
}

export const sendPasswordResetToEmail = (email) => {
	return sendPasswordResetEmail(auth, email);
}


/**
 * @template S
 * @param {string} collectionName The collection in firestore you want to access
 * @param {string | null} default_id The id of the document in the collection
 * @returns {[S, Dispatch<SetStateAction<S>>, () => Promise<void>, boolean]} [data, updateDoc, removeDoc, isLoading]
 * 
 * Returns a firebase document value, a function to update the document, a function to remove the document, and a boolean indicating whether the document is loading.
 */
const useDocument = (collectionName, default_id) => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [docu, setStateDoc] = React.useState();
	const [id, setID] = React.useState(default_id);

	const docRef = React.useMemo(
		() => (id ? doc(db, collectionName, id) : null),
		[collectionName, id]
	);

	const updateMemoDoc = React.useMemo(
		() => (nextStateValue) => {
			setIsLoading(true);
			if (docRef) {
				nextStateValue = {
					...nextStateValue,
					updatedAt: Timestamp.now(),
				}
				setDoc(docRef, nextStateValue, { merge: true }).then(() => {
					setIsLoading(false);
				});
			} else {
				nextStateValue = {
					...nextStateValue,
					createdAt: Timestamp.now(),
					updatedAt: Timestamp.now(),
				}
				addDoc(collection(db, collectionName), nextStateValue).then((newDocRef) => {
					setID(newDocRef.id);
					setIsLoading(false);
				});
			}
		},
		[docRef, collectionName]
	);

	const removeMemoDoc = React.useMemo(
		() => () => {
			setIsLoading(true);
			deleteDoc(docRef).then(() => {
				setID(null)
				setIsLoading(false);
			});
		},
		[docRef]
	);

	React.useEffect(() => {
		setID(default_id);
	}, [default_id]);

	React.useEffect(() => {
		if (docRef) {
			setIsLoading(true);
			return onSnapshot(docRef, (nextDocumentSnapshot) => {
				if (nextDocumentSnapshot.exists) {
					setStateDoc(nextDocumentSnapshot.data());
				} else {
					setStateDoc(null);
				}
				setIsLoading(false);
			});
		} else {
			setStateDoc(null);
		}
	}, [docRef]);
	return [docu, updateMemoDoc, removeMemoDoc, isLoading];
};

/**
 * @template T
 * @param {string} collectionPath
 * @param {[string, string, any][]?} wheres
 * an array of arrays, e.g., [['state', '==', 'CO'], ['name', '==', 'Denver']]
 * @param {[string, string][]?} orderBys
 * an array of arrays, e.g., [['state', 'asc'], ['name', 'desc']]
 * @returns {[T[], boolean]}
 */
const useQuery = (collectionPath, { wheres = [], orderBys = [] } = {}) => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [docs, setDocs] = React.useState([]);
	const collectionRef = React.useMemo(
		() => (collectionPath ? collection(db, collectionPath) : null),
		[collectionPath]
	);

	React.useEffect(() => {
		if (collectionRef) {
			setIsLoading(true);
			const whereParameters = wheres.map((w) => where(...w));
			const orderByParameters = orderBys.map((o) => orderBy(...o));
			const q = query(collectionRef, ...whereParameters, ...orderByParameters);
			return onSnapshot(q, (querySnapshot) => {
				const docs = querySnapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
					path: doc.ref.path,
				}));
				setDocs(docs);
				setIsLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- in case wheres or orderBys refer to a different array with the same values
	}, [collectionRef, JSON.stringify(wheres), JSON.stringify(orderBys)]);
	return [docs, isLoading];
};

/**
 * 
 * @template S
 * @param {string} path 
 * @param {S} data 
 * @returns {DocumentReference<S>}
 */
export const addDocument = async (path, data) => {
	return await addDoc(collection(db, path), data);
}

/**
 * 
 * @template S
 * @param {DocumentReference[]} refs
 * @returns {Promise<S[]>}
 */
export const getDocsFromRefs = (refs) => {
	return Promise.all(refs.map(async (ref) => {
		const doc = await getDoc(ref);
		return { ...doc.data(), id: doc.id, path: doc.ref.path }
	}));
}

export const useStorage = (path) => {
	const [url, setUrl] = useState("");
	// Create a reference to the file we want to download
	useEffect(() => {
		const fileRef = ref(storage, path);
		// Get the download URL
		getDownloadURL(fileRef)
			.then((url) => {
				setUrl(url);
			})
			.catch((error) => {
				switch (error.code) {
					case "storage/object-not-found":
						// File doesn't exist
						break;
					case "storage/unauthorized":
						// User doesn't have permission to access the object
						break;
					case "storage/canceled":
						// User canceled the upload
						break;

					case "storage/unknown":
						// Unknown error occurred, inspect the server response
						break;
					default:
						break;
				}
			});
	}, [path]);
	return [url, null];
};

/**
 * 
 * @param {string} path 
 * @param {File} file 
 * @returns {Promise<StorageReference>}
 */
export const uploadFile = async (path, file) => {
	const fileRef = ref(storage, path);
	return uploadBytes(fileRef, file).then(() => {
		return fileRef;
	}).catch((error) => {
		console.log("error uploading file", error);
		return null;
	});
};

/**
 * 
 * @param {string} path 
 * @param {File[]} files
 * @returns {StorageReference[]}
 */
export const uploadFiles = async (path, files) => {
	const fileRefs = [];
	for (let i = 0; i < files.length; i++) {
		const fileRef = await uploadFile(`${path}/${Date.now() + files[i].name}`, files[i]);
		fileRefs.push(fileRef);
	}
	return fileRefs;
};

export const deleteFile = async (path) => {
	const fileRef = ref(storage, path);
	return deleteObject(fileRef).catch((error) => {
		console.log("error deleting file", error);
	});
};

export const useUID = () => {
	const [uid, setUid] = useState(get(UID));

	useEffect(() => {
		setUid(get(UID));
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				set(UID, user.uid);
				setUid(user.uid);
			} else {
				remove(UID);
				setUid(null);
			}
		});
		return unsubscribe;
	}, []);

	return uid;
}

export const useUserEmail = () => {
	const [email, setEmail] = useState(get(UEMAIL));

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				set(UEMAIL, user.email);
				setEmail(user.email);
			} else {
				remove(UEMAIL);
				setEmail(null);
			}
		});
		return unsubscribe;
	}, []);

	return email;
}

/**
 * 
 * @returns {{
 *  uid: string,
 *  email: string,
 * 	emailVerified: boolean,
 * 	reload: () => void
 * }}
 */
export const useUser = () => {
	const [user, setUser] = useState(get(USER));
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				set(USER, {
					uid: user.uid,
					email: user.email,
					emailVerified: user.emailVerified,
				});
				setUser({
					uid: user.uid,
					email: user.email,
					emailVerified: user.emailVerified,
				});
			} else {
				remove(USER);
				setUser(null);
			}
		});
		return unsubscribe;
	}, []);

	const reload = React.useMemo(() => (user && !user?.emailVerified) ? () => {
		reloadEmailVerified().then((emailVerified) => {
			if (emailVerified) {
				setUser({
					...user,
					emailVerified: true,
				});
			}
		});
	} : null, [user]);


	return user ? { ...user, reload } : null;
}

/**
 * 
 * @param {string} path 
 * @returns {DocumentReference}
 */
export const getRef = (path) => {
	return doc(db, path);
}

/**
 * 
 * @param {DocumentReference} ref
 * @returns {Promise<object>}
 */
export const getDocu = async (ref) => {
	const docu = await getDoc(ref);
	return { ...docu.data(), id: docu.id, path: docu.ref.path };
}

/**
 * 
 * @param {string} teacherID
 * @returns {[TeacherPublic, Dispatch<SetStateAction<TeacherPublic>>, () => Promise<void>, boolean]}
 */
export const useTeacherPublic = (teacherID) => useDocument('teachers_public', teacherID);

/**
 * 
 * @param {string} teacherID 
 * @returns {[TeacherPrivate, Dispatch<SetStateAction<TeacherPrivate>>, () => Promise<void>, boolean]}
 */
export const useTeacherPrivate = (teacherID) => useDocument('teachers_private', teacherID);

/**
 * 
 * @param {string} teacherID 
 * @param {Description} description 
 * @returns {[Description[], boolean, (data: Description) => Promise<void>, boolean]}
 */
export const useTeacherDescriptions = (teacherID) => {
	const [, addDescription, ,] = useDocument(`teachers_public/${teacherID}/descriptions`, null);
	return [...useQuery(`teachers_public/${teacherID}/descriptions`), addDescription];
}

export const changeEmail = async (newEmail) => {
	let currentUser = auth.currentUser;
	const timeInterval = setInterval(() => {
		currentUser = auth.currentUser;
		if (currentUser) {
			clearInterval(timeInterval);
		}
	}, 100);
	return updateEmail(currentUser, newEmail);
}

export const changePassword = async (newPassword) => {
	let currentUser = auth.currentUser;
	const timeInterval = setInterval(() => {
		currentUser = auth.currentUser;
		if (currentUser) {
			clearInterval(timeInterval);
		}
	}, 100);
	return updatePassword(currentUser, newPassword);
}

/**
 * 
 * @param {string} teacherEmail 
 * @returns {[Student[], boolean]}
 */
export const useTeacherStudents = (teacherEmail) => {
	const wheres = useMemo(() => [["teacherEmails", "array-contains", teacherEmail || ""]], [teacherEmail]);
	const [collectionPath, setCollectionPath] = useState("students");
	const reload = useMemo(() => () => {
		setCollectionPath(null);
		setTimeout(() => {
			setCollectionPath("students");
		}, 100);
	}, []);
	return [...useQuery(collectionPath, { wheres }), reload];
}

/**
 * 
 * @param {string} studentID 
 * @returns {[Student, Dispatch<SetStateAction<Student>>, () => Promise<void>, boolean]}
 */
export const useStudent = (studentID) => {
	const [docID, setDocID] = useState(studentID);
	const reload = useMemo(() => () => {
		setDocID(null);
		setTimeout(() => {
			setDocID(studentID);
		}, 100);
	}, [studentID]);
	return [...useDocument("students", docID), reload];
}

/**
 * 
 * @param {string} studentID 
 * @param {Date} date 
 * @returns {[DailyRecord, (data: DailyRecord, id: string | null) => Promise<void>, boolean]}
 */
export const useRecordByDate = (studentID, date) => {
	const wheres = useMemo(() => [["date", "==", date]], [date]);
	const orderBys = useMemo(() => [[documentId(), "desc"]], []);
	const [, addRecord, ,] = useDocument(`students/${studentID}/records`, null);
	return [...useQuery(`students/${studentID}/records`, { wheres, orderBys }), addRecord];
}

/**
 * 
 * @param {string} studentID 
 * @param {string} recordID 
 * @returns {[DailyRecord, Dispatch<SetStateAction<DailyRecord>>, () => Promise<void>, boolean]}
 */
export const useRecord = (studentID, recordID) => useDocument(`students/${studentID}/records`, recordID);

/**
 * 
 * @param {string} teacherID 
 * @param {string} descriptionID 
 * @returns {[Description, Dispatch<SetStateAction<Description>>, () => Promise<void>, boolean]}
 */
export const useDescription = (teacherID, descriptionID) => useDocument(`teachers_public/${teacherID}/descriptions`, descriptionID);


/**
 * 
 * @param {string} parentEmail 
 * @returns {[Student[], boolean]}
 */
export const useParentsStudents = (parentEmail) => {
	const wheres = useMemo(() => [["parentEmails", "array-contains", parentEmail]], [parentEmail]);
	return useQuery("students", { wheres });
}

export const refreshUserEmailVerified = () => {
	auth.onAuthStateChanged(async (user) => {
		if (user) {
			await user.reload();
		}
	});
}
