import React from 'react';
import { useStyles } from '../pages/teacher/authentication/useStyles';
import { Box, Button, Typography } from '@mui/material';
import TesterSettings from './TesterSettings';

const HomeScreen = ({
    setOpenSignIn,
    setOpenSignUp,
}) => {
    const classes = useStyles();
    const icAboutUs = "/img/ic-about-us.svg";
    const icFAQ = "/img/ic-faq.svg";
    const icBlueLolipop = "/img/ic-blue-lolipop.svg";
    const icGreenLolipop = "/img/ic-green-lolipop.svg";

    return (
        <>
            <Box className={`${classes.container}`}>
                <Box className={`${classes.flexRow}`}>
                    <Box className={`${classes.flexColumn} ${classes.boxShadow}`} style={{ cursor: "pointer" }} onClick={() => window.open("https://cleverpop.com.au/#wp-block-themeisle-blocks-accordion-72b3990a")}>
                        <img src={icAboutUs} alt="About Us" />
                        <Typography variant="h5" textAlign="center" >About Us</Typography>
                    </Box>

                    <Box className={`${classes.flexColumn} ${classes.boxShadow}`} style={{ cursor: "pointer" }} onClick={() => window.open("https://cleverpop.com.au/#wp-block-themeisle-blocks-accordion-72b3990a")}>
                        <img src={icFAQ} alt="FAQ" />
                        <Typography variant="h5" textAlign="center">FAQ</Typography>
                    </Box>
                </Box>
                <Box className={`${classes.flexRow}`}>
                    <Box className={`${classes.flexColumn} ${classes.gap2}`} onClick={() => setOpenSignUp(true)} sx={{ cursor: 'pointer' }}>
                        <img src={icGreenLolipop} alt="Green Lolipop" />
                        <Button className={`${classes.button} ${classes.signUp}`} variant="contained" onClick={() => setOpenSignUp(true)} size="large">
                            <Typography variant="h5" noWrap>Sign Up</Typography>
                        </Button>
                    </Box>

                    <Box className={`${classes.flexColumn} ${classes.gap2}`} onClick={() => setOpenSignIn(true)} sx={{ cursor: 'pointer' }}>
                        <img src={icBlueLolipop} alt="Blue Lolipop" />
                        <Button variant="contained" className={`${classes.button} ${classes.signIn}`} size="large">
                            <Typography variant="h5" noWrap>Sign In</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <TesterSettings />
        </>
    )
};

export default HomeScreen;
