const cache = {};
const localStorage = typeof window === "undefined" ? {} : window.localStorage;

/**
 * Initialize cache from storage or with default
 * doesn't save default to storage, but puts it in the cache for sync get
 * @param {string} key - Key to initialize
 * @param {object} def - Default value to use
 * @param {string} prevVerKey - Key name of previous version from where to migrate
 * @param {function} callbackToMigrate - Callback function to call if data for previous version is found.
 *
 */
export const init = (key, def, prevVerKey, callbackToMigrate) => {
  const getAndParseFromStorage = (keyName) => {
    const value = localStorage[keyName];
    return value && value !== "undefined" ? JSON.parse(value) : undefined;
  };

  const prevVerValue = prevVerKey
    ? getAndParseFromStorage(prevVerKey)
    : undefined;
  if (prevVerValue) {
    const migratedValue = callbackToMigrate(prevVerValue);
    remove(prevVerKey); // clean previous version after migration
    if (migratedValue) {
      set(key, migratedValue); // cache and save migrated version
      return;
    }
  }

  const value = getAndParseFromStorage(key);
  cache[key] = value !== undefined ? value : def;
};

/**
 * Set data to cache and storage.
 * @param {string} key - Key to save data for
 * @param {object} value - data to save against key
 */
export const set = (key, value) => {
  cache[key] = value;
  localStorage[key] = JSON.stringify(value);
  return value;
};

/**
 * Remove key and data from cache and storage
 * @param {string} key - Key to remove
 */
export const remove = (key) => {
  // TODO restore value to default one
  cache[key] = undefined;
  localStorage.removeItem(key);
};

/**
 * Retrieve data from cache. Be sure to call init first
 * @param {string} key - Key to get data for
 */
export const get = (key) => {
  console.assert(key in cache, `did you forget to init('${key}')?`);
  return cache[key];
};
