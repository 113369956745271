import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './useStyles';
import { EMAILJS_DELELTE_ACCOUNT_TEMPLATE_ID, EMAILJS_PUBLIC_KEY, EMAILJS_SMTP_SERVICE_ID } from '../../../utils/constants';
import { addDetaultDescriptions, changeEmail, changePassword, useTeacherDescriptions, useTeacherPrivate, useTeacherPublic } from '../../../utils/firebase';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import Password from '../authentication/password';
import LockcodeField from '../lockcodefield';
import emailjs from '@emailjs/browser';
import ReactLoading from 'react-loading';
import EmailVerificationMessage from '../../../components/EmailVerificationMessage';
import { EditableDescriptionList } from './EditableDescriptionList';

/**
 * 
 * @param {{
 *  description: Description, 
 * }} props 
 */
export const DescriptionDisplay = (props) => {
    const {
        description,
    } = props;

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
            <Box>
                <Emoji unified={description.emoji} size={36} emojiStyle={EmojiStyle.NATIVE} />
            </Box>
            <Typography sx={{ flex: 1, outline: 'none' }}>
                {description.label}
            </Typography>
        </Box>
    )
}

/**
 * 
 * @param {{
 *  open: boolean,
 *  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
 * }} props 
 * @returns 
 */
const TeacherSettings = (props) => {
    const {
        open,
        setOpen,
        currentUser
    } = props;
    const classes = useStyles();
    const { uid, email, emailVerified, reload } = currentUser;
    const teacherID = uid;
    const currentEmail = email;
    const [emailError, setEmailError] = React.useState(false);
    const [updateProfileError, setUpdateProfileError] = React.useState(null);
    const [descriptions, isLoadingDescriptions, ] = useTeacherDescriptions(teacherID);
    const [teacherPublic, setTeacherPublic, , isLoadingTeacherPublic] = useTeacherPublic(teacherID);
    const [teacherPrivate, setTeacherPrivate, , isLoadingTeacherPrivate] = useTeacherPrivate(teacherID);
    const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);
    const [enableLockcode, setEnableLockCode] = React.useState(teacherPrivate?.lockcodeEnabled);

    const onSubmit = async (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const lockcode = e.target.lockcode.value;
        const email = e.target.email.value;
        const password = e.target.password.value;
        const newTeacherPublic = {
            ...teacherPublic,
            name,
        }
        const newTeacherPrivate = {
            ...teacherPrivate,
            lockcode,
            lockcodeEnabled: enableLockcode,
        }

        Promise.all([
            setTeacherPublic(newTeacherPublic),
            setTeacherPrivate(newTeacherPrivate),
            (email !== currentEmail) ? changeEmail(email) : Promise.resolve(),
            (password) ? changePassword(password) : Promise.resolve(),
        ]).then(() => {
            setEmailError(false);
            setOpen(false);
        }).catch((e) => {
            if (e.code === 'auth/password-does-not-meet-requirements') {
                setUpdateProfileError("Password does not meet requirements");
            }
            else {
                setEmailError(true);
            }
        });
    }

    const onDeleteAccount = () => {
        // send email to confirm
        emailjs.send(EMAILJS_SMTP_SERVICE_ID, EMAILJS_DELELTE_ACCOUNT_TEMPLATE_ID, {
            email: currentEmail,
            uid: teacherID,
        }, EMAILJS_PUBLIC_KEY).then(() => {
            setOpenDeleteAccount(false);
        });
    }

    React.useEffect(() => {
        if (descriptions.length === 0 && !isLoadingDescriptions) {
            addDetaultDescriptions(teacherID);
        }
    }, [descriptions, isLoadingDescriptions, teacherID]);

    React.useEffect(() => {
        setEnableLockCode(teacherPrivate?.lockcodeEnabled === false ? false : true);
    }, [teacherPrivate]);

    return (
        <>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box className={classes.settings}>
                    <Typography variant="h4" textAlign="center" fontWeight="bold" mb={3}>Teacher Settings</Typography>

                    {(isLoadingDescriptions || isLoadingTeacherPrivate || isLoadingTeacherPublic) ?
                        <ReactLoading type="spin" color="#000000" style={{ margin: "auto", width: "50px" }} /> :
                        <form style={{ margin: 'auto' }} onSubmit={onSubmit}>
                            <Box display="flex" justifyContent="space-between" gap={2} mb={3}>
                                <TextField required id="name" label="Full Name" variant="outlined" fullWidth defaultValue={teacherPublic?.name} sx={{ flex: 1 }} />
                                <LockcodeField defaultLockCode={teacherPrivate?.lockcode} enableLockcode={enableLockcode} setEnableLockCode={setEnableLockCode} />
                            </Box>

                            <Box display="flex" justifyContent="space-between" gap={2} mb={3}>
                                <TextField id="email" label="Email" variant="outlined" fullWidth defaultValue={currentEmail} error={emailError} />
                                <Password />
                            </Box>
                            {updateProfileError && <Typography variant="body2" color="error"> {updateProfileError} </Typography>}

                            <EditableDescriptionList
                                descriptions={descriptions}
                                teacherID={teacherID}
                            />

                            <EmailVerificationMessage email={email} emailVerified={emailVerified} reload={reload} />

                            <Box display="flex" justifyContent="space-evenly">
                                <Button variant="contained" color="error" size='large' onClick={() => setOpenDeleteAccount(true)}>Delete Account</Button>
                                <Button variant="contained" color="primary" size='large' type='submit'>Save</Button>
                            </Box>
                        </form>}
                </Box>
            </Modal>

            <Modal open={openDeleteAccount} onClose={() => setOpenDeleteAccount(false)}>
                <Box className={classes.settings} width={30}>
                    <Typography variant="h4" textAlign="center" fontWeight="bold" mb={3}>Delete Account</Typography>
                    <Typography textAlign="center" mb={3}>Are you sure you want to delete your account? We will consider your request and take action soon. This action cannot be undone.</Typography>
                    <Box>
                        <Button variant="contained" color="error" size='large' sx={{ float: 'right', mr: 2 }} onClick={onDeleteAccount}>Confirm</Button>
                        <Button variant="contained" color="primary" size='large' sx={{ float: 'left' }} onClick={() => setOpenDeleteAccount(false)}>Cancel</Button>
                    </Box>
                </Box>
            </Modal>
        </>

    )
}

export default TeacherSettings;