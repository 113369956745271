import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => 
    createStyles({
        addMoreModal: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "600px",
            background: '#F5F5F7',
            borderRadius: "20px",
            padding: "20px",
            boxShadow: `inset 0px 0px 5px ${theme.palette.primary.main}`,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
        },

        addMoreModalButtons: {
            display: "flex",
            justifyContent: "space-evenly"
        }
    })
)
