export const SWEET = "SWEET";
export const SOUR = "SOUR";
export const AVATARS = [
    '/avatars/lolly_dog.svg', 
    '/avatars/lolly_ninja.svg', 
    '/avatars/lolly_pirate.svg', 
    '/avatars/lolly_princess.svg', 
    '/avatars/lolly_cotton_candy_monster.svg',
    '/avatars/lolly_dinosaur.svg',
    '/avatars/lolly_superhero.svg',
    '/avatars/lolly_unicorn.svg',
];

export const DEFAULT_DESCRIPTIONS = [
    {
        label: "sharing",
        emoji: "1f46b",
        type: SWEET,
        isVisible: true,
    },
    {
        label: "being respectful",
        emoji: "2764-fe0f",
        type: SWEET,
        isVisible: true,
    },
    {
        label: "putting hand up",
        emoji: "1f91a",
        type: SWEET,
        isVisible: true,
    },
    {
        label: "being disrespecful",
        emoji: "1f44e",
        type: SOUR,
        isVisible: true,
    },
    {
        label: "hands on behaviour",
        emoji: "1f44a",
        type: SOUR,
        isVisible: true,
    },
    {
        label: "calling out",
        emoji: "1f62e",
        type: SOUR,
        isVisible: true,
    }
];

export const EMAILJS_PUBLIC_KEY = "AxzgF3774nXj7bELh";
export const EMAILJS_SMTP_SERVICE_ID = "service_me3fj46";
export const EMAILJS_DELELTE_ACCOUNT_TEMPLATE_ID = "template_u00l4dm";

export const CURRENT_URL = "CURRENT_URL";
