import { Box, Button, Modal, Popover, TextField, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './useStyles';
import EmojiPicker, { Emoji, EmojiStyle } from 'emoji-picker-react';
import { SWEET } from '../../../utils/constants';
import { useTeacherDescriptions } from '../../../utils/firebase';

/**
 * 
 * @param {{
 *  type: string,
 *  open: boolean,
 *  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
 *  setDescription: (data: Description, id: string | null) => Promise<void>
 * }} props 
 * @returns 
 */
const NewDescriptionForm = (props) => {
    const {
        type,
        open,
        setOpen,
        teacherID
    } = props;
    const classes = useStyles();
    const [emoji, setEmoji] = React.useState();
    const [emojiPickerAnchor, setEmojiPickerAnchor] = React.useState(null);
    const [, , setDescription] = useTeacherDescriptions(teacherID);

    const onSubmit = (e) => {
        e.preventDefault();
        const description = e.target.description.value;
        setDescription({
            label: description,
            emoji: emoji,
            type: type,
            isVisible: true
        });
        setOpen(false);
    }

    return (
        <>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box className={classes.descriptionForm}>
                    <Typography variant="h5" align="center" mb={3} fontWeight="bold">
                        {type === SWEET ? 'New Sweet Description' : 'New Sour Description'}
                    </Typography>

                    <form style={{ margin: 'auto' }} onSubmit={onSubmit}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} gap={2}>
                            <Box className={classes.emojiBox} onClick={(event) => setEmojiPickerAnchor(event.currentTarget)}>
                                <Emoji unified={emoji} size={36} emojiStyle={EmojiStyle.NATIVE} />
                            </Box>
                            <TextField required id="description" label="Description" variant="outlined" sx={{ flex: 1 }} />
                        </Box>

                        <Button variant="contained" size='large' sx={{ float: 'right' }} type='submit'>Save</Button>
                    </form>
                </Box>
            </Modal>

            <Popover open={Boolean(emojiPickerAnchor)} anchorEl={emojiPickerAnchor} onClose={() => setEmojiPickerAnchor(null)}>
                <EmojiPicker emojiStyle={EmojiStyle.NATIVE}
                    style={{ alignSelf: "center" }}
                    onEmojiClick={(emojiObject, event) => { setEmoji(emojiObject.unified); setEmojiPickerAnchor(null) }}
                    lazyLoadEmojis={true}
                />
            </Popover>
        </>

    )
}

export default NewDescriptionForm;