import { Box, Button, Collapse, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useStyles } from '../dashboard/useStyles';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useParentsStudents, useTeacherPrivate, useTeacherStudents, useUID, useUserEmail } from '../../../utils/firebase';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DIRECT_URL, LOCK, RECORD_DATE } from '../../../utils/shareStates';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';

const StudentsDrawer = () => {
    const classes = useStyles();
    const userEmail = useUserEmail();
    const uid = useUID();
    const [teacherStudents,] = useTeacherStudents(userEmail);
    const [parentStudents,] = useParentsStudents(userEmail);
    const [date, setDate] = RECORD_DATE.useSharedState();
    const [lock, setLock] = LOCK.useSharedState();
    const [url, setUrl] = DIRECT_URL.useSharedState();
    const [openStudentsDrawer, setOpenStudentsDrawer] = React.useState(false);
    const query = new URLSearchParams(window.location.search);
    const view = query.get('view');
    const teacherView = view === 'teacher';
    const [teacherPrivate, , ,] = useTeacherPrivate(uid);

    const handleDirectLink = (url) => {
        if (teacherView && teacherPrivate?.lockcodeEnabled !== false) {
            setLock(true);
        }
        setUrl(url);
        setOpenStudentsDrawer(false);
    }

    useEffect(() => {
        if (lock === false && url) {
            window.open(url, "_self");
            setUrl(null);
        }
    }, [lock, url, setUrl]);


    return (
        <Box className={classes.studentsButton} >
            <Button color="primary" size='large' onClick={() => setOpenStudentsDrawer(!openStudentsDrawer)}>
                <ExpandCircleDownRoundedIcon fontSize="large" sx={{
                    transform: openStudentsDrawer ? "rotate(180deg)" : "rotate(0deg)",
                }} />
            </Button>
            <Collapse in={openStudentsDrawer} timeout="auto" unmountOnExit>
                <Box display="flex" flexDirection="column" gap={2} mb={2} mx={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker value={date} onChange={(newValue) => {
                                newValue.$d.setHours(0, 0, 0, 0)
                                setDate(newValue);
                            }} />
                        </DemoContainer>
                    </LocalizationProvider>

                    <Divider />

                    <Box display='flex' flexDirection="column" maxHeight="50vh" overflow="auto" sx={{ "&::-webkit-scrollbar": { width: "0px" } }} gap={2}>
                        { (teacherView ? teacherStudents : parentStudents)?.map((student, index) => (
                            <Box key={index} display="flex" justifyContent="flex-start" alignItems="center" sx={{ cursor: "pointer" }} gap={2}
                                onClick={() => { handleDirectLink(`/student/${student?.id}?view=${view}`, "_self") }}>
                                <Box component="img" src={student?.avatar} width={100} height={100} borderRadius="50%" />
                                <Typography variant="h6" color="black" fontWeight="bold" textAlign="center">{student?.name}</Typography>
                            </Box>
                        ))}

                        <Button variant="outlined" color="primary" size='large' onClick={() => handleDirectLink(teacherView ? "/teacher" : "/parent", "_self")}>
                            <ArrowCircleLeftOutlinedIcon fontSize='large' />
                            <Typography variant="h6" color="primary" fontWeight="bold" textAlign="center" ml={1}>Dashboard</Typography>
                        </Button>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    )
}

export default StudentsDrawer;
