import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        '@global': {
            '*::-webkit-scrollbar': {
                width: 0
            },
            input: {
                color: 'black !important',
                WebkitTextFillColor: 'black !important',
            },
            '.__EmojiPicker__.epr-emoji-native': {
                width: 'auto !important',
            },
            div: {
                // role=tooltip
                '&[role="tooltip"]': {
                    // first type
                    '&:fisrt-of-type': {
                        display: 'none !important',
                    }
                }
            },
        },
        root: {
            width: "80%",
            display: "flex",
            height: "100vh",
            margin: "auto",
            flexDirection: "column",
            justifyContent: "space-around",
        },

        background: {
            position: "fixed",
            width: "50vw",
            overflow: "hidden",
            bottom: 0,
            pointerEvents: "none",
        },

        leftBackground: {
            position: "fixed",
            height: "100vh",
            width: "20vw",
            transform: "translateX(calc(-100% + 20vw))",
            objectFit: "cover",

        },

        rightBackground: {
            position: "fixed",
            height: "100vh",
            right: 0,
            width: "20vw",
            transform: "translateX(calc(100% - 20vw))",
            objectFit: "cover",
        },

        logo: {
            width: "250px",
            position: "absolute",
            top: "10px",
            left: "10px",
        },

        signoutButton: {
            position: "absolute !important",
            top: "50px",
            right: "20px",
            width: "250px",
            textTransform: "none",
            fontWeight: "bold !important",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)",
            background: `${theme.palette.primary.contrastText} !important`,
        },

        addStudentButton: {
            textTransform: "none",
            maxWidth: "300px",
            alignSelf: "center",
        },

        settings: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            minWidth: "500px",
            maxHeight: "80vh",
            background: '#F5F5F7',
            borderRadius: "20px",
            padding: "20px",
            boxShadow: `inset 0px 0px 5px ${theme.palette.primary.main}`,
            overflowY: "auto",
        },

        descriptionForm: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30vw",
            minWidth: "500px",
            background: 'white',
            borderRadius: "20px",
            padding: "20px",
            boxShadow: `inset 0px 0px 5px ${theme.palette.primary.main}`,
        },

        emojiBox: {
            width: "56px",
            height: "56px",
            border: `1px solid rgba(0, 0, 0, 0.23)`,
            borderRadius: "4px",
            display: "flex",
            justifyContent: "space-around",
            "& .__EmojiPicker__": {
                alignSelf: "center",
            }
        },

        avatar: {
            background: 'white',
            borderRadius: '6px',
        },

        selectingAvatar: {
            outline: `2px solid ${theme.palette.primary.main}`,
        },

        studentDisplay: {
            // width: "300px",
            // height: "300px",
            background: "white",
            borderRadius: "20px",
            boxShadow: `inset 0px 0px 5px ${theme.palette.primary.main}`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            zIndex: 1,
            cursor: "pointer",
            position: "relative",
        },

        studentSettingButton: {
            display: "flex",
            position: "absolute !important",
            top: 0,
            "& :hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
            "& svg": {
                transition: "0.3s ease-in-out !important",
                padding: "10px",
                borderRadius: "50%",
            }
        },

        studentDashboard: {
            minWidth: "800px",
            display: "flex",
            height: "100vh",
            width: "60%",
            margin: "auto",
            flexDirection: "column",
            justifyContent: "space-around",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
            "&>:nth-child(1)": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignSelf: "center",
            },
            "@media (max-width: 1024px) and (orientation: portrait)": {
                width: "90%",
            }
        },

        recordDisplay: {
            display: "flex",
            justifyContent: "center",
            paddingBottom: '2rem',
            "&>*": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "20px",
                borderRadius: "40px",
                alignSelf: "flex-start",
                gap: "20px",
                "&>button": {
                    background: "white",
                    "&>svg": {
                        width: "50px",
                        height: "50px",
                    }
                }
            },
            "&>:nth-child(1)": {
                // width
            },
            "&>:nth-child(2)": {
                background: theme.palette.primary.light,
                "&>div": {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                }
            },
            "& button": {
                padding: "0 !important",
                margin: "0 !important",
            }
        },

        studentsButton: {
            background: "white !important",
            border: "none !important",
            boxShadow: `0px 0px 5px ${theme.palette.primary.main}`,
            position: "absolute !important",
            top: " 180px",
            left: "10px",
            maxWidth: "250px",
            zIndex: 2,
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
        },

        studentsDrawer: {
            "&::-webkit-scrollbar": {
                width: "0px",
            },
            "& .MuiDrawer-paper": {
                width: "20vw",
                maxWidth: "250px",
                padding: "0 3vh 3vh 3vh",
                overflowY: "unset",
                "&::-webkit-scrollbar": {
                    width: "0px",
                },
                "& button": {
                    border: "none !important",
                },
                "& img": {
                    background: theme.palette.primary.light,
                }
            }
        },

        studentDrawerCloseButton: {
            position: "absolute !important",
            right: "calc(-3vh - 10px)",
            top: "5vh",
            "& svg": {
                background: "white",
                borderRadius: "50%",
            }
        },

        recordDescription: {
            alignSelf: "center",
            // width: "50px",
            height: "50px",
            display: "flex",
            cursor: "pointer",
        },

        addDescriptionButton: {
            background: "#0002",
            borderRadius: "10px",
            flexWrap: "wrap",
            padding: "10px",
            justifyContent: "space-between",
            width: '50px'
        },

        recordEmoji: {
            borderRadius: "50%",
            minWidth: '50px',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
        },

        backgroundSweet: {
            background: theme.palette.sweet.main,
        },

        backgroundSour: {
            background: theme.palette.sour.main,
        },

        recordSourDescriptionText: {
            // position: "absolute",
            background: theme.palette.sour.main,
            padding: "10px",
            borderRadius: "10px",
        },

        recordSweetDescriptionText: {
            // position: "absolute",
            // transform: 'translateX(-100%)',
            background: theme.palette.sweet.main,
            padding: "10px",
            borderRadius: "10px",
        },

        recordDescriptionEditor: {
            // position: "absolute",
            display: "flex",
        },

        transform100: {
            transform: "translateX(-100%)",
        },

        '@keyframes shake': {
            '0%': { transform: 'rotate(10deg)' },
            '50%': { transform: 'rotate(-10deg)' },
            '100%': { transform: 'rotate(10deg)' },
        },

        shake: {
            animation: '$shake 0.5s',
            animationIterationCount: 'infinite',
        },

        askLockcodePopup: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            display: "flex",

        }
    })
)
